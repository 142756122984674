<template>
    <div class="bingo">
        <template v-for="(card, index) in displayCards">
            <Document
                :key="'bingo-card-' + index"
                :index="index"
                :header-height="style.firstPageHeaderHeightInches"
                class="render-page"
            >
                <div
                    id="bingo_words"
                    class="bingo-card body-content"
                    :class="['columns-' + dimension, bingo.show_top_word ? ' hasTopWord' : '']"
                    :style="{
                        borderColor: bingo.card_color,
                        fontSize: style.font_size < 40 ? style.font_size + 'px' : '40px',
                        paddingTop: `${style.instructionPaddingBorderHeight}px`,
                    }"
                >
                    <span :id="`anchor-bingo_words-${index}`" class="position-absolute" style="top: 100px"></span>
                    <!-- Top Word -->
                    <template v-if="bingo.show_top_word">
                        <div
                            v-for="(num, innerIndex) in Number(dimension)"
                            :key="'top-word-' + innerIndex"
                            class="bingo-cell top-word"
                            :style="{
                                borderColor: bingo.card_color,
                                backgroundColor: bingo.card_color,
                            }"
                            @click="handleDimentionClick(innerIndex)"
                        >
                            {{ topWord[innerIndex] }}
                        </div>
                    </template>
                    <!-- Bingo Cells -->
                    <div
                        v-for="(item, cardIndex) in card"
                        :key="'bingo-cell' + cardIndex"
                        lang="en"
                        class="bingo-cell w-100 px-2"
                        :style="{
                            borderColor: bingo.card_color,
                            color: bingo.color,
                            overflow: 'hidden',
                        }"
                    >
                        <div class="w-100 h-100">
                            <span
                                v-if="isFreeSpace(cardIndex + 1)"
                                class="text-pre w-100 mb-0 bingo-inline-image"
                                @click="handleBingoTermClick(item.index)"
                            >
                                <div
                                    class="w-100 h-100 d-flex align-items-center justify-content-center"
                                    :class="getTextPlacementClass(item)"
                                >
                                    <InlineImage
                                        v-if="item?.term_image?.length"
                                        :key="item.term_image"
                                        :image-id="item.term_image"
                                        :size="bingo.content.style?.images?.size"
                                        :max-size="getMaxSize(item)"
                                        @change="refreshCallList"
                                    />
                                    <div
                                        v-if="item?.term?.length"
                                        ref="content"
                                        class="content w-100 d-flex flex-column justify-content-center"
                                        :class="[`fit-box-${item.index}`, { 'h-100': !item?.term_image?.length }]"
                                        :data-index="item.index"
                                        :style="getLineHeightStyle"
                                    >
                                        <div class="d-block w-100" :class="{ 'py-1': item?.term_image?.length }">
                                            {{ item.term }}
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <span
                                v-else
                                class="free-space d-flex align-items-center w-100 h-100 justify-center items-center"
                                :style="{ color: bingo.card_color }"
                            >
                                <b-iconstack>
                                    <b-icon stacked icon="star-fill" scale="0.75"></b-icon>
                                    <b-icon stacked icon="circle"></b-icon>
                                </b-iconstack>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="showCardCountContainer" class="mt-2 d-flex justify-content-center">
                    <span class="card-number-slot text-danger px-2 py-1" title="Card No (Not printed)">
                        <strong>{{ index + 1 }}</strong>
                        of
                        <strong>{{ cards.length }}</strong>
                    </span>
                </div>
            </Document>
        </template>

        <div
            v-if="showCardCountContainer"
            class="p-1 d-flex items-center justify-content-center more-cards"
            :hideHead="true"
        >
            <div class="bg-card-preview w-1/2 p-1 rounded">
                <span class="text-center p-1 d-block w-100">Card View Options</span>
                <div class="d-flex align-items-center justify-content-around bg-white rounded">
                    <b-dropdown :text="'View ' + displaySize" variant="success" class="m-2">
                        <b-dropdown-item @click="setDisplaySize(1)">View 1</b-dropdown-item>
                        <b-dropdown-item v-if="cards.length > 3" @click="setDisplaySize(3)">View 3</b-dropdown-item>
                        <b-dropdown-item v-if="cards.length > 5" @click="setDisplaySize(5)">View 5</b-dropdown-item>
                        <b-dropdown-item v-if="cards.length > 10" @click="setDisplaySize(10)">View 10</b-dropdown-item>
                        <b-dropdown-item @click="setDisplaySize('All')">View All</b-dropdown-item>
                    </b-dropdown>

                    <button
                        :disabled="displaySize <= 1"
                        class="btn hide-all"
                        :class="displaySize > 1 ? 'active' : 'inactive'"
                        @click="() => setDisplaySize(1)"
                    >
                        Hide All
                    </button>
                </div>
            </div>
        </div>

        <!-- Call List -->
        <Document
            v-show="wordbank.visible"
            ref="teacherCallList2"
            class="teacher-word-list2 render-page measurableHide"
            :hide-head="true"
            :is-call-list="true"
        >
            <div
                id="bingo_call_list2"
                class="position-relative h-100 d-flex flex-column"
                :class="$fonts.getFontClass(wordbankStyle.font)"
                :style="{
                    color: wordbankStyle.color,
                    fontSize: wordbankStyle.font_size + 'px',
                }"
            >
                <div id="callListHeader">
                    <span id="anchor-bingo_call_list2" class="position-absolute" style="top: -100px"></span>

                    <h1 v-show="bingo.show_header" class="text-center">
                        {{ document.title }}
                    </h1>
                    <h2 class="mb-4 border-bottom border-dark">Call List</h2>
                </div>

                <div id="call-list2" class="pl-2 call-list-item2 flex-grow-1 h-90 break-all">
                    <div class="w-50 mr-1">
                        <CallListItem
                            v-for="(item, index) in getWordBank"
                            :key="index"
                            :item="item"
                            :display-no="index + 1"
                            :image-id="item.term_image"
                            :bingo-has-image="bingoHasImage"
                            class="call-list-item"
                        ></CallListItem>
                    </div>
                </div>
            </div>
        </Document>

        <!-- Call List -->
        <Document
            v-for="(pageValues, page) in paginatedCalllist"
            v-show="wordbank.visible"
            ref="teacherCallList"
            :key="'test' + page"
            class="teacher-word-list render-page"
            :hide-head="true"
            :is-call-list="true"
        >
            <div
                id="bingo_call_list"
                class="position-relative"
                :class="$fonts.getFontClass(wordbankStyle.font)"
                :style="{
                    color: wordbankStyle.color,
                    fontSize: wordbankStyle.font_size + 'px',
                }"
            >
                <div class="call-list-header">
                    <span id="anchor-bingo_call_list" class="position-absolute" style="top: -100px"></span>
                    <h1 v-show="bingo.show_header" class="text-center">
                        {{ document.title }}
                    </h1>
                    <h2 class="mb-4 border-bottom border-dark calllist-title">Call List</h2>
                </div>

                <div id="call-list" class="pl-2 call-list word-break break-all">
                    <div class="w-50 mr-1">
                        <CallListItem
                            v-for="(item, index) in pageValues[1]['data']"
                            :key="`column-1-${index}`"
                            :item="item"
                            :display-no="pageValues[1]['starts'] + index"
                            :image-id="item.term_image"
                            :bingo-has-image="bingoHasImage"
                        ></CallListItem>
                    </div>
                    <div v-if="pageValues[2] && pageValues[2]['data']" class="w-50 mr-1">
                        <CallListItem
                            v-for="(item, index) in pageValues[2]['data']"
                            :key="`column-2-${index}`"
                            :item="item"
                            :display-no="pageValues[2]['starts'] + index"
                            :image-id="item.term_image"
                            :bingo-has-image="bingoHasImage"
                        ></CallListItem>
                    </div>
                </div>
            </div>
        </Document>
    </div>
</template>

<script>
import Chance from 'chance'
import { mapGetters, mapState } from 'vuex'
import InlineImage from '../../components/widgets/InlineImage.vue'
import CallListItem from '../../components/CallListItem.vue'
import InlineImagesMixin from '../../mixins/InlineImages'
import FittyTextMixin from '../../mixins/FittyText'
import Document from '../document.vue'

export default {
    name: 'Bingo',
    components: {
        InlineImage,
        CallListItem,
        Document,
    },
    mixins: [InlineImagesMixin, FittyTextMixin],
    props: ['preview', 'printable'],
    data() {
        return {
            cards: [],
            fittedClasses: [],
            showPreview: false,
            previewInterval: null,
            displaySize: 1,
        }
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            bingo: 'document/bingo',
            bingoItems: 'document/bingoItems',
            paginatedCalllist: 'document/paginatedCalllist',
            wordbank: 'document/wordbank',
            wordbankStyle: 'document/wordbankStyle',
            style: 'document/documentStyle',
            zoomRatio: 'document/getScaleRatio',
            inlineImages: 'document/bingoInlineImages',
            bingoWordbankStyle: 'document/bingoWordbankStyle',
            bingoWordbank: 'document/bingoWordbank',
            getWordBank: 'document/getSortedWordBank',
        }),
        topWord() {
            return this.bingo.top_word ? this.bingo.top_word.split('') : []
        },
        dimension() {
            return this.bingo.dimension
        },
        squared() {
            return this.dimension * this.dimension
        },
        gridTemplate() {
            return this.style.font_size > 40 ? '6' : '5'
        },
        gridBingo() {
            return this.style.font_size > 40 ? '2' : '3'
        },
        showCardCountContainer() {
            return !this.document.is_published && !this.printable
        },
        displayCards() {
            let cards = [...this.cards]
            cards = cards.map((card) => {
                let newCard = []
                let i = 0
                while (i < card.length) {
                    if (!this.isFreeSpace(newCard.length + 1)) {
                        newCard.push({ ...this.emptyItem })
                    }
                    newCard.push(card[i])
                    i++
                }

                if (newCard.length > this.squared) {
                    newCard = newCard.slice(0, this.squared)
                } else {
                    while (newCard.length < this.squared) {
                        newCard.push({ ...this.emptyItem })
                    }
                }

                return newCard
            })

            return this.document.is_published || this.displaySize == 'All' || this.printable
                ? cards
                : cards.slice(0, this.displaySize)
        },
        page_count() {
            return (this.cards?.length ?? 0) + 1
        },
        bingoHasImage() {
            const itemWithImage = this.getWordBank.find((item) => item.term_image?.length > 0)
            if (!itemWithImage) return false

            return true
        },
        imageMaxSize() {
            let newHeight = 96 - parseInt(this.style.font_size)
            return Math.max(newHeight, 48)
        },
        emptyItem() {
            return { term: '', term_image: '', index: -1 }
        },
    },
    watch: {
        '$nav.active_panel': function (newVal, oldVal) {
            if (this.$nav.active_panel == 'word-bank') {
                //get offsetTop of teacher call list * multiplpy it by our scale ratio
                let scrollTo = this.$refs.teacherCallList.$el.offsetTop * this.zoomRatio

                //scroll to the right place
                window.scrollTo(0, scrollTo)
            } else {
                //if going back to Body or Header on bingo, scroll back to the top.
                if (oldVal == 'word-bank' && (newVal == 'header' || newVal == 'body')) {
                    window.scrollTo(0, 0)
                }
            }
        },
        'bingo.word_bank_visible': function () {
            this.$nextTick(() => {
                if (this.bingo.word_bank_visible) {
                    this.$refs.teacherCallList.$el.scrollIntoView()
                } else {
                    const workspaceElm = bingo.getElementById('workspace')
                    if (workspaceElm) workspaceElm.scrollTop = 0
                }
            })
        },
        'bingo.num_of_cards': function () {
            this.getCards()
        },
        'bingo.dimension': function () {
            this.getCards()
        },
        'bingo.show_top_word': function () {
            this.getCards()
        },
        'bingo.top_word': function () {
            this.getCards()
        },
        'bingo.reuse_terms': function () {
            this.getCards()
        },
        'bingo.shuffle_seed': function () {
            this.getCards()
        },
        'bingo.content.items': {
            handler: function () {
                this.getCards()
                this.refreshCallList()
            },
            deep: true,
        },
        page_count: {
            handler(newValue, oldValue) {
                if (newValue != oldValue) this.$store.dispatch('document/setPageCount', newValue)
            },
            immediate: true,
        },
        'bingo.content.style.images': {
            async handler() {
                await this.$nextTick()
                this.resizeItems()
            },
            deep: true,
            immediate: true,
        },
        bingoWordbankStyle: {
            handler: function () {
                this.$nextTick(() => {
                    this.refreshCallList()
                })
            },
            deep: true,
        },
        bingoWordbank: {
            handler: function () {
                this.$nextTick(() => {
                    this.refreshCallList()
                })
            },
            deep: true,
        },
        'wordbank.visible': {
            handler: function () {
                this.$nextTick(() => {
                    this.$store.dispatch('document/scaleDocument', true)
                })
            },
        },
        'bingo.free_spaces': {
            immediate: true,
            deep: true,
            handler() {
                this.getCards()
            },
        },
    },
    created() {
        this.getCards()
    },
    mounted() {
        this.$nextTick(function () {
            this.resizeItems()

            setTimeout(() => {
                //Wait for card resizes
                this.resizeItems()
            }, 300)

            this.refreshCallList()
        })

        this.checkReadyState()
    },
    updated() {
        /**
         * Collapse cards if the displaySize is more than 1
         */
        if (this.showPreview && this.previewInterval == null) {
            this.setShowPreview(false)
            this.setDisplaySize(1)
        }
        this.$nextTick(() => {
            this.resizeItems()
        })
    },
    methods: {
        isFreeSpace(index) {
            if (this.bingo.free_spaces.indexOf(index) >= 0) {
                return false
            } else {
                return true
            }
        },
        getCards() {
            let cardShuffle = this.bingo.shuffle_seed
            let items = this.bingoItems
            this.cards = [] //reset to blank
            const freeSpaces = this.squared - this.bingo.free_spaces.length

            // adds a new value font_size to object
            for (let i = 0; i < this.bingo.num_of_cards; i++) {
                let tempItems = []
                let shuffle = new Chance(cardShuffle + i)
                for (let item in items) {
                    if (this.itemHasContent(items[item])) {
                        let tempItem = {
                            term: items[item].term,
                            term_image: items[item].term_image,
                            index: item,
                            font_size: items[item].font_size,
                        }
                        tempItems.push(tempItem)
                    }
                }

                //if there are no temp items add a blank one to avoid any odd breaks
                if (tempItems.length == 0) {
                    tempItems.push('')
                }
                //check to make sure we have items to begin with with tempItems.length
                if (tempItems.length <= freeSpaces) {
                    if (this.bingo.reuse_terms && items.length > 0) {
                        //backfill with existing words
                        let dups = tempItems
                        while (tempItems.length < freeSpaces) {
                            tempItems = tempItems.concat(dups)
                        }
                        if (tempItems.length > freeSpaces) tempItems = tempItems.slice(0, freeSpaces)
                    } else {
                        // blanks fill in the rest
                        while (tempItems.length < freeSpaces) {
                            tempItems.push({ ...this.emptyItem })
                        }
                    }
                    tempItems = shuffle.shuffle(tempItems)
                } else {
                    tempItems = shuffle.shuffle(tempItems)
                    tempItems = tempItems.slice(0, freeSpaces)
                }

                this.cards.push(tempItems)
            }
        },
        handleBingoTermClick(index) {
            this.$store.dispatch('document/setWidgetStatus', {
                openBingoWords: true,
                focusedItem: { index },
            })
        },
        handleDimentionClick(index) {
            this.$store.dispatch('document/setWidgetStatus', {
                bingoCardSetup: true,
                focusedItem: { index },
            })
        },
        setShowPreview(state) {
            this.showPreview = state

            //Preview interval is used to prevent reset of preview while showPreview is updated
            // But allow reset if other values are updated
            this.previewInterval = setInterval(() => {
                clearInterval(this.previewInterval)
                this.previewInterval = null
            }, 500)
        },
        setDisplaySize(size) {
            this.displaySize = size

            if (size > 1 || size == 'All') {
                this.setShowPreview(true)
            }
            this.$store.dispatch('document/scaleDocument', true)
        },
        itemHasContent(item) {
            return (item.term && item.term.trim().length) || (item.term_image && item.term_image.trim().length)
        },
        getTextPlacementClass(item) {
            if (!item?.term?.length || !item?.term_image?.length) return ''

            let paddingClass = ''
            if (item?.term_image?.length) {
                paddingClass = this.bingo.content.style?.images?.text_placement
            }
            return (
                (this.bingo.content.style?.images?.text_placement === 'below' ? `flex-column` : `flex-column-reverse`) +
                ` ${paddingClass}`
            )
        },
        getLineHeightStyle(item) {
            if (item?.term_image?.length) {
                return { lineHeight: `1.2` }
            }
            return { lineHeight: `1.5` }
        },
        getMaxSize(item) {
            if (item?.term?.length) {
                return this.imageMaxSize
            }
            return 0
        },
        async refreshCallList() {
            await this.$store.dispatch('document/paginateCallList', this.getWordBank)
            this.$store.dispatch('document/scaleDocument', true)
        },
        checkReadyState() {
            if (document.readyState === 'complete') {
                this.refreshCallList()
            } else {
                setTimeout(this.checkReadyState, 100)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import 'Scss/base.scss';

.teacher-word-list {
    p {
        margin: 0px 0px 10px;
    }
}

.text-fit-cell {
    display: inline-block;
    white-space: nowrap;
}

.published {
    .bingo {
        .bingo-card {
            .bingo-cell {
                cursor: inherit !important;
            }
        }
    }
}

.bingo {
    .bingo-card {
        display: grid;
        justify-content: center;

        .bingo-cell {
            border: 1px solid;
            display: flex;
            max-width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            &.top-word {
                font-weight: bold;
                background-color: $black;
                color: $white;
            }

            p {
                padding: 10px;
                max-height: 100%;
                width: max-content;
                overflow-wrap: break-word;
                hyphens: manual;
                transition: all 0.3s ease-in;
            }
        }

        &.columns-3 {
            grid-template-columns: repeat(3, math.div(7.2in, 3));
            grid-template-rows: repeat(3, math.div(7.2in, 3));

            .free-space {
                font-size: 150px;
            }
        }

        &.columns-4 {
            grid-template-columns: repeat(4, math.div(7.2in, 4));
            grid-template-rows: repeat(4, math.div(7.2in, 4));

            .free-space {
                font-size: 130px;
            }
        }

        &.columns-5 {
            grid-template-columns: repeat(5, math.div(7.2in, 5));
            grid-template-rows: repeat(5, math.div(7.2in, 5));

            .free-space {
                font-size: 100px;
            }
        }

        &.columns-6 {
            grid-template-columns: repeat(6, math.div(7.2in, 6));
            grid-template-rows: repeat(6, math.div(7.2in, 6));

            .free-space {
                font-size: 90px;
            }
        }

        &.columns-7 {
            grid-template-columns: repeat(7, math.div(7.2in, 7));
            grid-template-rows: repeat(7, math.div(7.2in, 7));

            .free-space {
                font-size: 70px;
            }
        }

        &.hasTopWord {
            &.columns-3 {
                grid-template-rows: 2em repeat(3, math.div(7.2in, 3));
                transition: all 1s ease-in;
            }

            &.columns-4 {
                grid-template-rows: 2em repeat(4, math.div(7.2in, 4));
                transition: all 1s ease-in;
            }

            &.columns-5 {
                grid-template-rows: 2em repeat(5, math.div(7.2in, 5));
                transition: all 1s ease-in;
            }

            &.columns-6 {
                grid-template-rows: 2em repeat(6, math.div(7.2in, 6));
                transition: all 1s ease-in;
            }

            &.columns-7 {
                grid-template-rows: 2em repeat(7, math.div(7.2in, 7));
                transition: all 1s ease-in;
            }

            &.columns-17 {
                grid-template-rows: 2em repeat(7, math.div(7.2in, 7.5));
                transition: all 1s ease-in;
            }
        }
    }
}

.bingo > .attribution:after {
    content: 'Created with My Bingo Maker' !important;
    background-image: url('/images/bingo-mark.svg') !important;
}

#call-list {
    height: 8.5in;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.content {
    width: 100%;
    min-height: 15px;
    overflow: hidden;
}
.more-cards {
    margin-bottom: 0.25in;
}

.w-1\/2 {
    width: 50%;
}

.bg-card-preview {
    background: #f5f5f5;
}

.hide-all.inactive {
    background: #f1f1f1;
}

.hide-all.active {
    background: #bdbdbd;
}

.card-number-slot {
    background: rgba(242, 210, 210, 0.48);
    border-radius: 5px;
    font-size: 11px !important;
}

.bingo-inline-image {
    .below {
        padding-top: 0.15rem;
    }
    .above {
        padding-bottom: 0.15rem;
    }
}

.break-all {
    word-wrap: break-word;
}

.h-90 {
    height: 90%;
}

.measurableHide {
    pointer-events: none;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
}
</style>
